(function($, _window) {

  function onJumpLink(e) {
    if(_window.location.hash) {
      setTimeout(function() {
        var element;
        if(_window.location.hash === '#ongoing-support' || _window.location.hash === '#emergency-shelter' || _window.location.hash === '#prevention-education') {
          openTabbedContainer(_window.location.hash);
          element = '#services-section-three';
        } else {
          element = _window.location.hash;
        }
        var $jumpElement = $(element);
        if($jumpElement.length) {
          var $navBar = $('#page-header');
          var currentScroll = $('html').scrollTop();
          var jumpTo = $jumpElement.offset().top;
          // if((jumpTo - 25) < currentScroll && currentScroll < (jumpTo + 25)) {
          //   return false;
          // }
          jumpTo -= $('#page-header-padding').outerHeight();
          $("html, body").animate({ scrollTop: jumpTo }, 500);
        }
      }, 500);
    }
  }

  function openTabbedContainer(hash) {
    setTimeout(function() {
      if(hash === '#ongoing-support') {
        $('[data-tab]').eq(1).trigger('click');
      } else if(hash === '#emergency-shelter') {
        $('[data-tab]').eq(0).trigger('click');
      } else if(hash === '#prevention-education') {
        $('[data-tab]').eq(2).trigger('click');
      }
    }, 500);
  }

  $(onJumpLink);

  $(function() {
    $("a[href*='#']").on('click', function(e) {
      e.preventDefault();
      var parts = $(e.currentTarget).attr('href').split('#');
      if(parts[1] !== 'story' && window.location.href.indexOf(parts[0]) > -1) {
        if($('#'+parts[1]).length) {
          _window.location.hash = parts[1];
          onJumpLink();
        } else {
          document.location.href = $(e.currentTarget).attr('href');
        }
      } else {
        document.location.href = $(e.currentTarget).attr('href');
      }
    });
  });

})(jQuery,window);
