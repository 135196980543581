(function($) {

  function initMailerButton() {

    const $buttons = $('.signup-form-button');
    if($buttons.length) {
      $buttons.on('click', (e) => {
        e.preventDefault();
        $('#mailFormHolder').remove();
        const $button = $(e.currentTarget);
        const href = $button.attr('href');
        const $popup = $('<div id="mailFormHolder"><button class="close">X</button></div>');
        const $frame = $('<iframe src="'+href+'"></iframe>');
        $popup.append($frame);
        $('body').append($popup);

        new Tether({
          element: $popup[0],
          target: $button[0],
          attachment: 'top right',
          targetAttachment: 'bottom left',
          constraints: [
            {
              to: 'scrollParent',
              pin: true
            }
          ]
        });

        $popup.find('.close').on('click', () => {
          $('#mailFormHolder').fadeOut(350, () => {
            $('#mailFormHolder').remove();
          });
        });
        return false;
      });
    }

  }

  $(initMailerButton);

})(jQuery);
