(function($) {

  function mobileNav() {
    $('#mobile-nav-toggle').click(() => {
      $('#mobile-nav ul.menu').height($(window).height() - $('#page-top-bar').outerHeight());
      $('#mobile-nav').slideToggle();
    });
  }

  $(mobileNav);

  function initStickyNavBar() {
    const isHome = $('body').hasClass('page-home');
    const $window = $(window);
    const $navBar = $('#page-header');
    const $desktopNav = $navBar.find('#desktop-nav');
    const $pageHeaderPadding = $('#page-header-padding');
    setTimeout(() => {
      const navBarHeight = $navBar.outerHeight();
      $pageHeaderPadding.css({"padding-top": navBarHeight});
    }, 100);
    $navBar.css({"position": "fixed"});
    let isDown = false;
    $(window).on('scroll', $.throttle(150, () => {
      if(!isDown && $window.scrollTop() > 0) {
        isDown = true;
        $(parentNavItemSelector).removeClass('show-sub-menu');
        $navBar.css({"box-shadow": "0 1px 1px rgba(0, 0, 0, 0.1)"});
        if(isHome) {
          $desktopNav.stop().animate({"top": "-5.1rem", "margin-bottom": "-5.1rem", "margin-top": "0"}, 150);
        }
      } else if(isDown && $window.scrollTop() <= 0) {
        isDown = false;
        $(parentNavItemSelector).removeClass('show-sub-menu');
        $navBar.css({"box-shadow": ""});
        if(isHome) {
          $desktopNav.stop().animate({"top": "0", "margin-bottom": "0", "margin-top": "2rem"}, 150);
        }
      }
    }));
  }

  $(initStickyNavBar);

  let isSubNavMenuOpen = false;
  let $subNavMenuOpen = null;
  let closeSubNavMenuTimer = null;
  const parentNavItemSelector = '#desktop-nav .menu > li';

  function initSubNavMenus() {
    $(parentNavItemSelector).on('mouseenter', onParentNavItemEnter);
    $(parentNavItemSelector).on('mouseleave', onParentNavItemLeave);
  }

  function onParentNavItemEnter(event) {
    clearTimeout(closeSubNavMenuTimer);
    const $item = $(event.currentTarget);
    const $subNavMenuOpen = $item.find('.sub-menu');
    if($subNavMenuOpen.length) {
      $(parentNavItemSelector).removeClass('show-sub-menu');
      positionAndShowSubNavMenuWithParent($subNavMenuOpen, $item);
    }
  }

  function onParentNavItemLeave(event) {
    closeSubNavMenuTimer = setTimeout(() => {
      $(parentNavItemSelector+'.show-sub-menu .sub-menu').animate({'opacity': 0}, {duration: 50, always: (e) => {
        $(parentNavItemSelector).removeClass('show-sub-menu');
        $(e.elem).css({'opacity': 1});
      }});
    }, 1000);
  }

  function positionAndShowSubNavMenuWithParent($subNavMenu, $parentNavItem) {
    const isNotSticky = (parseInt($('#desktop-nav').css('margin-top')) > 0);
    const fixedPosition = {left: $parentNavItem.offset().left, top: (isNotSticky) ? $parentNavItem.offset().top : $parentNavItem.position().top + parseInt($parentNavItem.css('padding-bottom')) + 1};
    let anchorTop = fixedPosition.top + $parentNavItem.outerHeight();
    if($('iframe.goog-te-banner-frame').length && $('iframe.goog-te-banner-frame').is(':visible')) {
      anchorTop += $('iframe.goog-te-banner-frame').height();
    }
    if(!isNotSticky && $('#safety-message').is(':visible')) {
      anchorTop += $('#safety-message').outerHeight();
    }
    const cssComputed = {position: "fixed", top: anchorTop, right: "auto", left: "auto", 'min-width': $parentNavItem.outerWidth()};
    if(fixedPosition.left + 300 > $(window).width()) {
      cssComputed.right = $(window).outerWidth() - (fixedPosition.left + $parentNavItem.outerWidth());
      $subNavMenu.addClass('aligned-right');
    } else {
      cssComputed.left = fixedPosition.left;
      $subNavMenu.removeClass('aligned-right');
    }
    $subNavMenu.css(cssComputed);
    $parentNavItem.addClass('show-sub-menu');
  }

  $(initSubNavMenus);

})(jQuery);
