(function($) {

  function initTabs() {
    if($('.tabbed-container').length) {
      $('.tabbed-container').each((i, container) => {
        const $container = $(container);
        $container.find('[data-tab]').each((i, button) => {
          $(button).on('click', (e) => {
            const $button = $(e.currentTarget);
            const tab = $button.data('tab');
            $container.find('div[class^="tab-content-"]').addClass('hidden');
            $container.find('[data-tab]').removeClass('open');
            $container.find(".tab-content-"+tab).removeClass('hidden');
            $container.find('[data-tab="'+tab+'"]').addClass('open');
          });
        });
        $container.find('[data-tab]').first().trigger('click');
      });
    }
  }

  $(initTabs);

})(jQuery);
