(function($) {

  function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  function asyncLoadGTranslate() {
    window.googleTranslateInit = function() {
      new google.translate.TranslateElement({pageLanguage: 'en', includedLanguages: 'es,ru,zh-CN,vi', layout: google.translate.TranslateElement.InlineLayout.SIMPLE}, 'google_translate_element');
    };
    (function () {
        var s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateInit';
        var x = document.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
    })();
  }

  function initSafetyMessage() {

    asyncLoadGTranslate();
    if(getCookie('safetyMessageSeen') !== 'yes') {
      const $safetyMessage = $('#safety-message');
      const $pageHeaderPadding = $('#page-header-padding');
      $safetyMessage.show();

      $('#safety-message #close').on('click', () => {
        $('#safety-message #close').off('click');
        const $navBar = $('#page-header');
        $safetyMessage.slideUp({
          duration: 1000,
          progress: (anim) => {
            $pageHeaderPadding.css({"padding-top": $navBar.outerHeight()});
          }
        });
        $('#safety-message').slideUp(350);
      });

      // finally just set the cookie because they saw it
      const expirydate = new Date();
      expirydate.setTime(expirydate.getTime() + (7*24*60*60*1000));
      document.cookie = "safetyMessageSeen=yes; expires="+ expirydate.toUTCString() +"; path=/";
    }

  }

  $(initSafetyMessage);

})(jQuery);
