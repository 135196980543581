(function($) {

  // function initFormSelectDefaults() {
  //   const $selectsRequired = $('select.wpcf7-validates-as-required');
  //   if($selectsRequired.length) {
  //     $selectsRequired.each((i, item) => {
  //       console.log(item);
  //       console.log($(item).find('option').eq(0));
  //       $(item).find('option').eq(0).val('').prop('disabled', true);
  //     });
  //   }
  // }
  //
  // $(initFormSelectDefaults);

})(jQuery);
