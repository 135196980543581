(function($) {

  function initFAQ() {
    const $questions = $('dl.accordion-list dt');
    if($questions.length) {
      $questions.on('click', function(e) {
        $(e.currentTarget).next('dd').slideToggle();
        $(e.currentTarget).toggleClass('open');
      });
      $questions.first().trigger('click');
    }
  }

  $(initFAQ);

})(jQuery);
