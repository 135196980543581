(function($) {

  function initPartnersSlideshow() {
    const $slideshow = $('.slideshow');
    if($slideshow.length) {
      $slideshow.slick({
        appendArrows: false,
        dots: true,
        appendDots: $slideshow.siblings('.dots'),
        autoplay: true,
        autoplaySpeed: 5000,
        fade: true,
      });
    }
  }

  $(initPartnersSlideshow);

})(jQuery);
