(function($) {

  function initPopularPostSlideshow() {
    const $slideshow = $('#posts-popular .slideshow');
    if($slideshow.length) {
      $slideshow.slick({
        appendArrows: false,
        dots: true,
        appendDots: $('#popular-posts-dots'),
        autoplay: true,
        autoplaySpeed: 5000,
        fade: true,
      });
    }
  }

  function initCommentPostSubmit() {
    const commentCompleteRegex = /#comment-\d+$/;
    if(commentCompleteRegex.test(window.location) && $('#comment-form').length) {
      $('#comment-form').addClass('submitted');
      $('#comment-form h3').text("Comment Submitted");
      $('html, body').animate({ scrollTop: $('#comment-form').offset().top}, 100);
    }
  }

  function initSearchButton() {
    if($('.search-button').length) {
      $('.search-button').on('click', (e) => {
        $('#searchsubmit').trigger('click');
      });
    }
  }

  function initReadMore() {
    if($('body.page-template-tertiary-eleven').length) {
      $('p').html(function(_, text) {
        if (text.indexOf('<!--more-->') >= 0) {
          return text.replace('<!--more-->', '<a href="#" class="readMoreLink">+ Read More</a>' );
        }
      });
      $('a.readMoreLink').each((i,item) => {
        const $link = $(item);
        const $linkContainer = $link.parent();
        const $readMoreContent = $("<div class='read-more-content-"+i+"' style='display: none;'></div>");
        $linkContainer.find("~ *").wrapAll($readMoreContent);
        $link.click((e) => {
          e.preventDefault();
          const $content = $('.read-more-content-'+i);
          if($content.is(':visible')) {
            $content.hide();
            $link.text('+ Read More');
          } else {
            $content.show();
            $link.text('- Show Less');
          }
        });
      });
    }
  }

  $(initPopularPostSlideshow);
  $(initCommentPostSubmit);
  $(initSearchButton);
  $(initReadMore);

})(jQuery);
