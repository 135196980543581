(function($) {

  function initVideoPlayers() {
    if($('.video-open-btn').length) {
      const videoIDRegex = /(youtu\.be\/)([\w\d]+)|(watch\?v=)([\w\d]+)/i;
      $('.video-open-btn').on('click', (e) => {
        e.preventDefault();
        const $target = $(e.currentTarget);
        const regexMatch = videoIDRegex.exec($target.attr('href'));
        const videoID = (regexMatch[2] === undefined) ? regexMatch[4] : regexMatch[2];
        const $videoFrame = $('<iframe width="100%" height="100%" src="https://www.youtube-nocookie.com/embed/'+videoID+'?rel=0" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>');
        const $closeBtn = $('<button id="close-video">X</button>');
        $('#video-holder').remove();
        const $videoMask = $('<div id="video-mask">').css('opacity', 0);
        const $videoHolder = $('<div id="video-holder">').css('opacity', 0);
        $videoMask.appendTo('body');
        $videoHolder.appendTo('body');
        $videoMask.animate({'opacity': 1}, 800);
        $videoHolder.animate({'opacity': 1}, 800);
        $videoFrame.appendTo($videoHolder);
        $closeBtn.appendTo($videoHolder);
        $closeBtn.on('click', closeVideoPlayer);
        $(window).on('click.close-video', (e) => {
          const $targetClicked = $(e.target);
          if($targetClicked.is('#video-holder') || $targetClicked.parent('#video-holder').length) {
            return true;
          }
          $(window).off('click.close-video');
          closeVideoPlayer();
        });
        return false;
      });
    }
  }

  function closeVideoPlayer() {
    $('#video-mask').fadeOut(800);
    $('#video-holder').fadeOut(800, () => {
      $('#video-holder').remove();
      $('#video-mask').remove();
    });
  }

  $(initVideoPlayers);

})(jQuery);
